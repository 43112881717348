import { Dogma } from 'shared/dogma';
import { httpclient } from '@/api/client';

export const dogma = new Dogma(httpclient);

export const doSendPasswordForgetMail = ({ email }) => httpclient.post('/password-forget', { email })
  .then((response) => response)
  .catch((error) => error.response);

export const doSetPassword = ({ password }) => httpclient.post('/set-password', { password })
  .then((response) => response)
  .catch((error) => error.response);

export const doOAuthRegister = (payload) => httpclient.post('/oauth-register', payload)
  .then((response) => response)
  .catch((error) => error.response);

export const doOAuthLogin = (payload) => httpclient.post('/oauth-login', payload)
  .then((response) => response)
  .catch((error) => error.response);

export const doConfirmInvitation = (payload) => httpclient.post('/confirm-invitation', payload)
  .then((response) => response)
  .catch((error) => error.response);

export const doRegisterViaEmail = (payload) => httpclient.post('/register-mail', payload)
  .then((response) => response)
  .catch((error) => error.response);

export const doConfirmRegisterCode = (payload) => httpclient.post('/confirm-register-code', payload)
  .then((response) => response)
  .catch((error) => error.response);

export const doLoginUser = ({ email, password }) => httpclient.post('/login', {
  email,
  password,
})
  .then((response) => response)
  .catch((error) => error.response);

export const doLogoutUser = () => httpclient.post('/logout')
  .then((response) => response)
  .catch((error) => error.response);

export const doSamlLoginUser = ({ email }) => httpclient.get('/saml/login', { params: { email } })
  .then((response) => response)
  .catch((error) => error.response);

export const doLoginPublishedView = ({ savedViewId, passphrase }) => httpclient.post('/login-published', { savedViewId, passphrase })
  .then((response) => response)
  .catch((error) => error.response);

export const doDeleteLanguages = ({ model, ids, languages }) => httpclient.post(
  `/delete-language/${model}`,
  { ids, languages },
)
  .then((response) => response)
  .catch((error) => error.response);

export const doGetChartData = ({ charts, userScopeTree, intervalConfig }) => httpclient.post('/chart-data', {
  charts,
  userScopeTree,
  intervalConfig,
})
  .then((response) => response)
  .catch((error) => error.response);

export const doResetAccount = (payload) => httpclient.post('/delete-account', payload)
  .then((response) => response)
  .catch((error) => error.response);

export const doValidateCSV = (blob, lang) => httpclient.post(`/csv-validate?lang=${lang}`, blob)
  .then((response) => response)
  .catch((error) => error.response);

export const doImportCSV = (blob, lang) => httpclient.post(`/csv-import?lang=${lang}`, blob)
  .then((response) => response)
  .catch((error) => error.response);

export const doUploadFile = (formData, onUploadProgress = () => {
}) => httpclient.post(
  '/file',
  formData,
  { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress },
).then((response) => response);

export const doCsvExport = ({ filter }) => httpclient.get('/csv-export', { params: { filter: JSON.stringify(filter) } });

export const doGetPublicAccountData = (signUpPathName) => httpclient.post(
  '/public-account-data',
  { signUpPathName },
).then((response) => response).catch((error) => error.response);

export const doConnectBot = (activity) => httpclient.post(
  '/microsoft-bot-connect',
  { activity },
).then((response) => response).catch((error) => error.response);

export const doSendContactSales = ({
  firstName,
  lastName,
  email,
  plan,
  licences,
  message,
  hsTicketCategory,
  subject,
}) => httpclient.post('/contact-sales', {
  firstName,
  lastName,
  email,
  plan,
  licences,
  message,
  hsTicketCategory,
  subject,
})
  .then((response) => response)
  .catch((error) => error.response);

export const doMSTeamsLogin = ({ accessToken, provider, refreshToken, tokenExpiry, signedTrackedRequest, samlResponse }) => httpclient.post(
  '/msteams/login',
  {
    accessToken: decodeURIComponent(accessToken),
    provider: decodeURIComponent(provider),
    refreshToken: decodeURIComponent(refreshToken),
    tokenExpiry: decodeURIComponent(tokenExpiry),

    // saml
    signedTrackedRequest: decodeURIComponent(signedTrackedRequest),
    samlResponse: decodeURIComponent(samlResponse),
  },
);

export const doProvisioningStatus = (userProvisioning) => httpclient.get(`/provisioning/status/${userProvisioning.uid}`).then((response) => response).catch((error) => error.response);

export const doProvisioningSync = (userProvisioning) => httpclient.post(`/provisioning/sync/${userProvisioning.uid}`).then((response) => response).catch((error) => error.response);

export const doGetSlackConsentUrl = (data) => httpclient.post('/slack-bot/consent-url', data)
  .then((response) => response)
  .catch((error) => error.response);

export const doGetStripePortal = (data) => httpclient.post('/stripe', data)
  .then((response) => response)
  .catch((error) => error.response);

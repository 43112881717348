<template>
  <m-card
    ref="card"
    :class="classes"
    no-padding
    :level="1"
    @contextmenu.prevent="$emit('context-menu-clicked', $event)"
    @click="open"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <div
      v-if="draggingOverTop"
      class="_drag-over-top"
    />
    <div
      v-if="draggingOverRight"
      class="_drag-over-right"
    />
    <div
      v-if="draggingOverLeft"
      class="_drag-over-left"
    />
    <div
      v-if="draggingOverBottom"
      class="_drag-over-bottom"
    />
    <m-content
      :padding-top="9"
      :padding-bottom="layout === 'default' ? 6 : 9"
      :padding-x="9"
      :class="['_content', $store.state.breakpoint.smAndDown ? '-mobile' : '', `-${layout}`]"
      :style="bodyStyle"
    >
      <div
        :class="['_actions', hover ? '-hover' : '']"
      >
        <m-btn-group>
          <m-btn
            v-if="inlineEditingMode"
            fab
            icon="central-preview"
            small
            light
            :style="{ borderRight: `1px solid ${$colors.grey.lighten4}`}"
            hide-border
            @click.stop="open"
          />
          <m-btn
            v-else-if="canEdit"
            fab
            icon="edit"
            small
            :style="{ borderRight: `1px solid ${$colors.grey.lighten4}`}"
            light
            hide-border
            @click.stop="edit"
          />
          <m-dropdown
            v-if="!readOnly"
            v-model:value="showEllipsisMenu"
            :title="$t('general.actions')"
          >
            <m-btn
              fab
              icon="ellipsis"
              hide-border
              small
              light
              @click.stop="showEllipsisMenu = true"
            />
            <template #overlay>
              <goals-context-menu
                component-type="div"
                :goal-ids="[entity.uid]"
                :can-create="canCreate"
                can-create-subgoal
                :create-subgoal-loading="createBeneathLoading"
                :show-expand-buttons="showExpandButtons"
                :read-only="readOnly"
                @create-beneath="$emit('create-beneath', $event)"
                @expand-all="$emit('expand-all', $event)"
                @collapse-all="$emit('collapse-all', $event)"
                @relocate="handleRelocateCtxMenu"
                @hide="showEllipsisMenu = false"
              />
            </template>
          </m-dropdown>
        </m-btn-group>
      </div>
      <div class="_left">
        <div
          class="_top"
          @click="handleClick"
          @mousedown="handleMouseDown"
        >
          <item-title
            :icons="[
              { value: goalTypeIcon, showBackground: true, size: 16 },
              { value: icon, size: 16 },
            ]"
            :class="['_item-title', `-${titleLayout}`]"
            wrap-title
          >
            <template #title>
              <simple-editor
                class="_title"
                tag="div"
                :initial-value="entity.title"
                :read-only="!inlineEditingMode"
                :placeholder="$t('list.addTitle')"
                :prevent-scroll-on-focus="preventScrollOnFocus"
                full-width
                auto-focus
                @enter="blur"
                @escape="blur"
                @update:value="updateTitle"
              />
            </template>
          </item-title>
        </div>
        <div
          :class="['_bottom', `-${layout}`]"
          :style="inlineEditingMode ? { flexDirection: 'column' } : { flexDirection: 'row' }"
        >
          <div
            v-for="prop in visibleProps"
            :key="prop.key"
            class="_property"
          >
            <template v-if="prop.property.component === GOAL_PROGRESS">
              <template v-if="layout === 'default'">
                <m-focusable
                  v-if="(entity.publishedAt !== null && entity.progressMeasurement !== goalProgressMeasurement.none)"
                  hide-border
                  type="clickable"
                  :read-only="readOnly || !canComment"
                  small
                  class="_property"
                  @click.stop="$emit('progress-clicked', entity)"
                >
                  <goal-progress
                    :goal="entity"
                    class="_progress"
                    no-padding
                    hide-details
                    show-small-data-source-indicator
                    :m-style="{ fontStyle: { fontSize: 'small' } }"
                    :clickable="canComment"
                  />
                </m-focusable>
              </template>
            </template>
            <template v-else-if="prop.property.type === propertyType.status">
              <template v-if=" layout === 'default'">
                <m-focusable
                  v-if="(entity.publishedAt === null || entity.progressMeasurement !== goalProgressMeasurement.none)"
                  hide-border
                  type="clickable"
                  class="_property"
                  :style="{ display: 'inline-flex' }"
                  :read-only="entity.publishedAt === null || readOnly || !canComment"
                  small
                  @click.stop="$emit('progress-clicked', entity)"
                >
                  <status-prop
                    :goal="entity"
                    xs
                    :m-style="{ fontStyle: { fontSize: 'small' } }"
                  />
                </m-focusable>
              </template>
            </template>
            <m-focusable
              v-else-if="prop.property.component === TIME_DIFF"
              small
              read-only
              type="clickable"
              hide-border
            >
              <component-prop
                :entity="entity"
                small
                :prop="prop"
                show-tooltip
                show-icon
                :tooltip-placement="layout === 'default' || inlineEditingMode ? 'left' : 'top'"
                :property-values-key="propertyValuesKey"
                :read-only="readOnly"
                :m-style="{ fontStyle: { fontSize: 'small' } }"
              />
            </m-focusable>
            <div
              v-else
              @click="handleClick"
              @mousedown="handleMouseDown"
            >
              <m-tooltip
                :placement="layout === 'default' || inlineEditingMode ? 'left' : 'top'"
                :disabled="!prop.visible"
                :mouse-enter-delay="0.5"
              >
                <goal-property-form-item
                  :property="prop.property"
                  :goal-picker-service="{}"
                  hide-border
                  small
                  popup
                  wrap
                  show-placeholder-icon
                  :m-style="{ fontStyle: { fontSize: 'small' } }"
                  :read-only="readOnly || !canEdit || !inlineEditingMode"
                  :full-width="inlineEditingMode && !prop.visible"
                  :placeholder="getPlaceholder(prop)"
                  :goal="entity"
                  :update-properties="(val) => updateProperty(val, prop.property, entity)"
                />
                <template #title>
                  {{ prop.label }}
                </template>
              </m-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="layout === 'compact'"
        class="_right"
      >
        <m-focusable
          v-if="(entity.publishedAt === null || entity.progressMeasurement !== goalProgressMeasurement.none)"
          class="_progress"
          hide-border
          :read-only="entity.publishedAt === null || readOnly || !canComment"
          type="clickable"
          @click.stop="$emit('progress-clicked', entity)"
        >
          <status-prop
            v-if="entity.publishedAt === null"
            :goal="entity"
          />
          <goal-progress
            v-else-if="entity.progressMeasurement !== goalProgressMeasurement.none"
            :goal="entity"
            show-small-data-source-indicator
            :clickable="canComment"
          />
        </m-focusable>
      </div>
    </m-content>
    <slot name="footer" />
  </m-card>
</template>

<script>
import ComponentProp from '@/components/list/ComponentProp.vue';
import GoalProgress from '@/components/goal/GoalProgress.vue';
import GoalPropertyFormItem from '@/components/goal/GoalPropertyFormItem.vue';
import GoalsContextMenu from '@/components/goal/GoalsContextMenu.vue';
import ItemTitle from '@/components/ItemTitle.vue';
import SimpleEditor from '@/components/SimpleEditor.vue';
import StatusProp from '@/components/property/StatusProp.vue';
import useClickOutside from 'shared/composables/click-outside';
import useDebounce from '@/composables/debounce';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import useInlineEditing from '@/composables/inline-editing';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { GOAL_PROGRESS, TIME_DIFF } from '@/lib/props/custom-types';

import {
  accessPolicyType,
  goalProgressMeasurement,
  propertyType,
  propertyVisibility,
} from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed, ref, toRef } from 'vue';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { isVisible } from '@/lib/display-props/display-props';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'GoalCard',
  props: {
    entity: {
      type: Object,
      required: true,
    },
    identifier: {
      type: [String, Number],
      required: true,
    },
    updateProperty: {
      type: Function,
      required: true,
    },
    titleLayout: {
      type: String,
      default: 'row',
    },
    props: {
      type: Array,
      required: true,
    },
    maxBodyHeight: {
      type: Number,
      default: 0,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      default: 'default',
      validator(value) {
        return ['compact', 'default'].includes(value);
      },
    },
    selected: {
      type: Boolean,
      default: false,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    selectedSecondary: {
      type: Boolean,
      default: false,
    },
    componentKey: {
      type: [String, Number],
      required: true,
    },
    tiny: {
      type: Boolean,
      default: false,
    },
    propertyValuesKey: {
      type: String,
      default: 'properties',
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    createBeneathLoading: {
      type: Boolean,
      default: false,
    },
    draggingOverTop: {
      type: Boolean,
      default: false,
    },
    draggingOverBottom: {
      type: Boolean,
      default: false,
    },
    showExpandButtons: {
      type: Boolean,
      default: false,
    },
    draggingOverRight: {
      type: Boolean,
      default: false,
    },
    draggingOverLeft: {
      type: Boolean,
      default: false,
    },
    preventScrollOnFocus: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'option-clicked',
    'progress-clicked',
    'create-beneath',
    'arrow-down',
    'select',
    'open',
    'prop-clicked',
    'expand-all',
    'collapse-all',
    'context-menu-clicked',
  ],
  components: {
    ComponentProp,
    GoalPropertyFormItem,
    SimpleEditor,
    GoalProgress,
    GoalsContextMenu,
    ItemTitle,
    StatusProp,
  },
  setup(props) {
    const { debounce, flushAll } = useDebounce();
    const { goalTypeIcon } = useGoalTypeProperty();
    const { userLang } = useLoggedInUser();
    const { goalSettings } = useGoalSettings();
    const inlineEditingSvc = useInlineEditing();
    const { updateSingle } = useGoals();

    const inlineEditingMode = computed(() => inlineEditingSvc.isInlineEditing(props.identifier).value);
    const element = ref(null);

    useClickOutside(inlineEditingSvc.reset, inlineEditingMode, element, { callCallbackBeforeUnmount: true });

    return {
      debounce,
      element,
      updateSingle,
      userLang,
      inlineEditingMode,
      inlineEditingSvc,
      goalSettings,
      debounceFlushAll: flushAll,
      goalTypeIcon: computed(() => goalTypeIcon(toRef(props, 'entity').value)),
    };
  },
  data() {
    return {
      propertyType,
      goalConfig,
      TIME_DIFF,
      GOAL_PROGRESS,
      goalProgressMeasurement,
      goalCtxMenuRelocate: 0,
      showEllipsisMenu: false,
      hover: false,
    };
  },
  computed: {
    bodyStyle() {
      if (this.maxBodyHeight === 0) {
        return {};
      }

      return {
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: `${this.maxBodyHeight}px`,
      };
    },
    classes() {
      return [
        'goal-card',
        this.highlighted ? '-highlighted' : '',
        this.selected ? '-selected' : '',
        this.selectedSecondary ? '-selected-secondary' : '',
        this.inlineEditingMode ? '-inline-edit-mode' : '',
        this.hideHover ? '' : '-show-hover',
        this.hover ? '-hover' : '',
      ];
    },
    visibleProps() {
      const visible = (p) => {
        if (p.property.component === GOAL_PROGRESS) {
          return true;
        }
        if (p.property.component === TIME_DIFF) {
          return this.entity[p.property.edgeName] !== null;
        }

        return isVisible({
          property: {
            ...p.property,
            key: p.key,
            isDirect: p.isDirect,
            goalVisibility: propertyVisibility.hideWhenEmpty,
          },
          goal: this.entity,
        });
      };

      const props = this.props
        .filter((p) => !p.hideInProps && p.show && !p.isTitle)
        .map((p) => ({
          ...p,
          visible: visible(p),
        }));

      if (this.inlineEditingMode) {
        return props.filter((p) => p.property.type !== propertyType.lookup
            && p.property.component !== TIME_DIFF);
      }
      return props.filter((p) => p.visible);
    },
    icon() {
      return buildIconFromEntity(this.entity);
    },
    canEdit() {
      return (
        !this.readOnly
          && [accessPolicyType.full, accessPolicyType.write].includes(
            this.entity.accessRight,
          )
      );
    },
    canComment() {
      return (
        !this.readOnly
          && [
            accessPolicyType.full,
            accessPolicyType.write,
            accessPolicyType.comment,
          ].includes(this.entity.accessRight)
      );
    },
  },
  methods: {
    handleRelocateCtxMenu(val) {
      this.goalCtxMenuRelocate = val;
    },
    handleClick(event) {
      if (this.inlineEditingMode) {
        event.stopPropagation();
      }
    },
    getPlaceholder(property) {
      return this.$t('goalCard.addProperty', { label: property.label });
    },
    edit() {
      this.inlineEditingSvc.set(this.identifier);
    },
    updateTitle(value) {
      const update = () => {
        this.updateSingle({ uid: this.entity.uid, title: value }).catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }));
      };

      this.debounce(update, 500);
    },
    blur() {
      this.debounceFlushAll();
      this.$emit('select', { goal: this.entity });
      this.inlineEditingSvc.reset();
    },
    handleMouseDown(event) {
      if (this.inlineEditingMode) {
        event.stopPropagation();
      }
    },
    open(event) {
      if (event.button === 2) {
        return;
      }
      this.$emit('open', this.entity);
    },
  },
  mounted() {
    this.element = this.$refs.card.$el;
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
@import "shared/assets/scss/box-shadow";

$drag-target-size: 4px;
$drag-target-distance: 8px;

.goal-card {
  position: relative;
  border-radius: $border-radius-sm;
  cursor: pointer;

  ._drag-over-top {
    position: absolute;
    top: -$drag-target-distance;
    right: 0;
    left: 0;
    z-index: 88;
    width: 100%;
    height: $drag-target-size;
    border-radius: calc($drag-target-size / 2);
    pointer-events: none;
    background: $highlighted-color-dark;
    opacity: 1;
  }

  ._drag-over-bottom {
    position: absolute;
    right: 0;
    bottom: -$drag-target-distance;
    left: 0;
    z-index: 88;
    width: 100%;
    height: $drag-target-size;
    border-radius: calc($drag-target-size / 2);
    pointer-events: none;
    background: $highlighted-color-dark;
    opacity: 1;
  }

  ._drag-over-left {
    position: absolute;
    top: 0;
    right: 0;
    left: -$drag-target-distance;
    z-index: 88;
    width: $drag-target-size;
    border-radius: calc($drag-target-size / 2);
    height: 100%;
    pointer-events: none;
    background: $highlighted-color-dark;
    opacity: 1;
  }

  ._drag-over-right {
    position: absolute;
    right: -$drag-target-distance;
    top: 0;
    z-index: 88;
    width: $drag-target-size;
    border-radius: calc($drag-target-size / 2);
    height: 100%;
    pointer-events: none;
    background: $highlighted-color-dark;
    opacity: 1;
  }

  &.-hover {
    &:not(.-inline-edit-mode) {
      &:not(.-selected) {
        &.-show-hover {
          background-color: $hover-color;
        }
      }
    }
  }

  &.-selected {
    &:not(.-inline-edit-mode) {
      background-color: $highlighted-color;

      &.-hover {
        background-color: darken($highlighted-color, 5%);
      }
    }
  }

  &.-selected-secondary {
    &:not(.-inline-edit-mode) {
      background-color: $highlighted-color-light;

      &.-hover {
        background-color: darken($highlighted-color-light, 5%);
      }
    }
  }

  &.-highlighted {
      background-color: $highlighted-color-light;
      box-shadow: inset 0 0 0 1px $primary-color;

      &.-hover {
        background-color: darken($highlighted-color-light, 5%);
      }
  }

  ._content {
    position: relative;

    &.-compact {
      display: flex;
      align-items: center;
    }

    ._actions {
      position: absolute;
      top: 1.6rem;
      right: 1.6rem;
      z-index: 2;
      display: none;
      background-color: white;
      border-radius: $btn-border-radius;

      &.-hover {
        display: flex;
      }

      @include box_shadow(1);
    }

    ._left {
      flex-grow: 1;
      flex-shrink: 1;
      align-items: center;

      ._top {
        display: flex;

        ._item-title {
          ._title {
            margin-top: .4rem;
            font-size: $font-size-4;
            font-weight: $font-weight-medium;
          }

          &.-row {
            ._title {
              margin-top: -.3rem;
            }

            display: flex;
            align-items: flex-start;
          }
        }
      }

      ._bottom {
        margin-top: .8rem;
        margin-left: -.6rem;

        &.-compact {
          display: flex;
          flex-wrap: wrap;
        }

        ._property {
          overflow: hidden;
          margin-bottom: .2rem;
        }
      }
    }

    ._right {
      margin-left: 3rem;
      display: flex;
      height: 100%;
      flex-basis: 20rem;
      flex-grow: 0;
      flex-shrink: 0;
      align-items: center;
      justify-content: flex-end;

      ._progress {
        width: 100%;
        font-size: $font-size-2;
      }
    }

    &.-mobile {
      flex-wrap: wrap;

      ._left {
        ._bottom {
          flex-wrap: wrap;
        }
      }

      ._right {
        flex-grow: 1;
        margin-top: .6rem;
        margin-left: 0;
      }
    }
  }
}
</style>

import axios from 'axios';
import { errorType } from '@/constants.json';
import { hasSignedRequest, token } from '@/lib/signed-request';
import { installRequestID } from '@/lib/request';
import { ref } from 'vue';

const BASE_URL = '/api/v1';

export const httpclient = axios.create({ baseURL: BASE_URL });
httpclient.interceptors.request.use(installRequestID);

const store = ref(null);
export const setStore = (s) => { store.value = s; };

httpclient.interceptors.request.use((config) => {
  const url = `${config.baseURL}${config.url}`;
  if (hasSignedRequest(window.location.hash, url)) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token(window.location.hash, url)}`,
      },
    };
  }

  return config;
}, (error) => Promise.reject(error));

const sendToLogin = (error) => {
  if (error.response.status !== 401) {
    return false;
  }

  return !['/api/v1/logout', '/logout'].includes(error.config.url);
};

const statusServiceUnavailable = 503;

const setVersion = (header) => {
  const version = header['api-version-sha'];
  const semverVersion = header['api-version'];
  if (version === undefined || version === null) {
    return;
  }

  if (semverVersion === undefined || semverVersion === null) {
    return;
  }

  store.value.commit('APP_VERSION_RETRIEVED', { version, semverVersion });
};

httpclient.interceptors.response.use(
  (response) => { setVersion(response.headers); return response; },
  (error) => {
    if (error.response === undefined || error.response === null) {
      // we are probably offline
      // TODO: add offline handler
      return error;
    }

    setVersion(error.response.headers);

    if (error.response.status === statusServiceUnavailable && error.response.data.includes(errorType.maintenanceMode)) {
      setTimeout(() => {
        store.value.commit('ENABLE_MAINTENANCE_MODE');
      }, 100);
    }

    if (sendToLogin(error)) {
      setTimeout(() => {
        store.value.commit('USER_NOT_LOGGED_IN');
      }, 100);
    }

    return Promise.reject(error);
  },
);

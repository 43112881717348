import AccessGroupDetails from '@/views/AccessGroupDetails.vue';
import AccountSettings from '@/views/AccountSettings.vue';
import AccountSpaceRedirect from '@/views/AccountSpaceRedirect.vue';
import CreateForm from '@/views/CreateForm.vue';
import CreateFormTemplate from '@/views/CreateFormTemplate.vue';
import DashboardDetails from '@/views/DashboardDetails.vue';
import Dashboards from '@/views/Dashboards.vue';
import DataSourceDetails from '@/views/DataSourceDetails.vue';
import FormList from '@/views/FormList.vue';
import FormResults from '@/views/FormResults.vue';
import FormShare from '@/views/FormShare.vue';
import FormTemplateList from '@/views/FormTemplateList.vue';
import FormTemplateWrapper from '@/views/FormTemplateWrapper.vue';
import FormWrapper from '@/views/FormWrapper.vue';
import GiveFeedback from '@/views/GiveFeedback.vue';
import GoalCycles from '@/views/GoalCycles.vue';
import GoalDetails from '@/views/GoalDetails.vue';
import GoalInsights from '@/views/GoalInsights.vue';
import GoalSettings from '@/views/GoalSettings.vue';
import GoalStatusSettings from '@/views/GoalStatusSettings.vue';
import GoalTypes from '@/views/GoalTypes.vue';
import GoalsExplorer from '@/views/GoalsExplorer.vue';
import Home from '@/views/Home.vue';
import IdentityAndProvisioning from '@/views/IdentityAndProvisioning.vue';
import IntegrationSettings from '@/views/IntegrationSettings.vue';
import Logout from '@/components/Logout.vue';
import MSTeamsAccountSpaceRedirect from '@/views/MSTeamsAccountSpaceRedirect.vue';
import MSTeamsTeamDetails from '@/views/MSTeamsTeamDetails.vue';
import MyNotifications from '@/views/MyNotifications.vue';
import NewForm from '@/views/NewForm.vue';
import NewFormTemplatePreview from '@/views/NewFormTemplatePreview.vue';
import NotFound from '@/views/NotFound.vue';
import OAuthCodeGrantRedirect from '@/views/OAuthCodeGrantRedirect.vue';
import OAuthRedirect from '@/lib/oauth-redirect';
import Onboarding from '@/views/Onboarding.vue';
import PasswordForget from '@/views/PasswordForget.vue';
import PlanningDetails from '@/views/PlanningDetails.vue';
import Plannings from '@/views/Plannings.vue';
import Plans from '@/views/Plans.vue';
import Profile from '@/views/Profile.vue';
import ProfileSettings from '@/views/ProfileSettings.vue';
import PropertiesView from '@/views/PropertiesView.vue';
import PublishedViewWrapper from '@/views/PublishedViewWrapper.vue';
import Register from '@/views/Register.vue';
import ScheduledReports from '@/views/ScheduledReports.vue';
import SetPassword from '@/views/SetPassword.vue';
import SpaceDetails from '@/views/SpaceDetails.vue';
import SpaceList from '@/views/SpaceList.vue';
import Success from '@/views/Success.vue';
import Survey from '@/views/Survey.vue';
import UpdateDetails from '@/views/UpdateDetails.vue';
import UpdateFeedExplorer from '@/views/UpdateFeedExplorer.vue';
import UpdateScheduleDetails from '@/views/UpdateScheduleDetails.vue';
import UpdatesList from '@/views/UpdatesList.vue';
import UpdatesListOthers from '@/views/UpdatesListOthers.vue';
import UserList from '@/views/UserList.vue';
import i18n from '@/lang';
import msauth from '@/lib/msauth';
import {
  ACCESS_GROUP_LIST,
  ACCOUNT_SETTINGS,
  CREATE_FORM,
  FIELD_FACTORS,
  FORM,
  FORM_DETAILS,
  FORM_RESULTS,
  FORM_SEND_CHANNELS,
  FORM_SEND_OPTIONS,
  FORM_SEND_PARTICIPANTS,
  FORM_SEND_SETTINGS,
  FORM_SEND_VERIFY,
  FORM_SETTINGS,
  FORM_SHARE,
  FORM_TEMPLATE_WRAPPER,
  FORM_WRAPPER,
  GOALS_EXPLORER,
  GOAL_DETAILS,
  GOAL_INSIGHTS,
  HOME,
  NEW_FORM,
  NEW_FORM_TEMPLATE_PREVIEW,
  PROFILE,
  PROFILE_SETTINGS,
  QUESTION_BANK,
  QUESTION_BANK_FORM_TEMPLATES,
  QUESTION_BANK_TEMPLATE_DETAILS,
  QUESTION_BANK_TEMPLATE_LIST,
  QUESTION_BANK_TEMPLATE_LIST_FORM_TEMPLATES,
  SPACE_DETAILS, UPDATES_EXPLORER,
  USER_FIELDS_NO_SELECTION, USER_LIST,
} from '@/route-names';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { MAIN_LAYOUT, PLAIN, SETTINGS_LAYOUT } from '@/lib/layout';
import { createRouter, createWebHashHistory } from 'vue-router';
import { datadogRum } from '@datadog/browser-rum';
import { moduleFlag, routeName } from 'shared/constants.json';
import { routeNameToAccess } from '@/route-access';
import { showOnboarding } from '@/lib/onboarding/onboarding';

export default function initRouter(store) {
  const router = createRouter({
    history: createWebHashHistory(),
    linkExactActiveClass: '-active',
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      return { x: 0, y: 0 };
    },
    routes: [
      {
        path: '/login',
        name: routeName.logIn,
        redirect: '/',
      },
      {
        path: '/sso-login',
        name: routeName.ssoLogIn,
        redirect: '/',
      },
      {
        path: '/signup',
        name: routeName.signUp,
        component: Register,
        meta: {
          layout: PLAIN,
          noAuth: true,
          title: () => i18n.t('views.register'),
        },
      },
      {
        path: '/partner-signup/:partnerId',
        name: routeName.partnerSignUp,
        component: Register,
        meta: {
          layout: PLAIN,
          noAuth: true,
          title: () => i18n.t('views.register'),
        },
      },
      {
        path: '/signup/:signUpPathName',
        name: routeName.signUpLandingPage,
        component: Register,
        meta: {
          layout: PLAIN,
          noAuth: true,
          title: () => i18n.t('views.registerLandingPage'),
        },
      },
      {
        path: '/forgot-password',
        name: routeName.forgetPassword,
        component: PasswordForget,
        meta: {
          layout: PLAIN,
          noAuth: true,
          title: () => i18n.t('views.passwordForgetTitle'),
        },
      },
      {
        path: '/set-password',
        name: routeName.setPassword,
        component: SetPassword,
        meta: {
          layout: PLAIN,
          noAuth: true,
          title: () => i18n.t('views.setPasswordTitle'),
        },
      },
      {
        path: '/',
        props: true,
        name: HOME,
        component: Home,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('views.home'),
        },
      },
      {
        path: '/profiles/:userId',
        props: true,
        name: PROFILE,
        component: Profile,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('views.profile'),
        },
      },
      {
        path: '/account',
        name: routeName.accountSpace,
        component: AccountSpaceRedirect,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('views.accountSpace'),
        },
      },
      {
        path: '/space/:optionId',
        name: SPACE_DETAILS,
        component: SpaceDetails,
        props: true,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('views.spaceDetails'),
        },
      },
      {
        path: '/dashboards/overview',
        name: routeName.dashboards,
        component: Dashboards,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('views.dashboards'),
        },
      },
      {
        path: '/dashboards/:pageId',
        name: routeName.dashboardDetails,
        component: DashboardDetails,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('views.dashboardDetails'),
        },
      },
      {
        path: '/dashboards/reports',
        name: routeName.scheduledReports,
        component: ScheduledReports,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('dashboards.reports'),
        },
      },
      {
        path: '/dashboards/goal-insights',
        name: GOAL_INSIGHTS,
        component: GoalInsights,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('views.goalInsights'),
          flags: [moduleFlag.goals],
          accessGroupFlags: routeNameToAccess(GOAL_INSIGHTS),
        },
      },
      {
        path: '/survey-templates',
        name: routeName.formTemplateList,
        component: FormTemplateList,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.surveyTemplateList'),
          flags: [moduleFlag.surveys],
        },
      },
      {
        path: '/instant-feedback-templates',
        name: routeName.instantFeedbackTemplateList,
        component: FormTemplateList,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('settingsNavigation.instantFeedbackTemplateList'),
          flags: [moduleFlag.instantFeedbacks],
        },
      },
      {
        path: '/give-feedback/:feedbackAnswerId',
        name: routeName.giveFeedback,
        component: GiveFeedback,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('views.giveFeedback'),
          flags: [moduleFlag.surveys],
        },
      },
      {
        path: '/form-templates-wrap/:formTemplateId',
        name: FORM_TEMPLATE_WRAPPER,
        component: FormTemplateWrapper,
        meta: {
          layout: PLAIN,
          title: () => i18n.t('views.formTemplateWrapper'),
          flags: [moduleFlag.surveys, moduleFlag.instantFeedbacks],
        },
        children: [
          {
            path: '/form-templates/:formTemplateId/create',
            name: routeName.createFormTemplate,
            component: CreateFormTemplate,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.createFormTemplate'),
              flags: [moduleFlag.surveys, moduleFlag.instantFeedbacks],
            },
          },
          {
            path: '/form-templates/:formTemplateId/settings',
            name: routeName.formTemplateLanguageSettings,
            component: CreateFormTemplate,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.formSettings'),
              flags: [moduleFlag.surveys, moduleFlag.instantFeedbacks],
            },
          },
          {
            path: '/form-templates/:formTemplateId/tags',
            name: routeName.formTemplateTags,
            component: CreateFormTemplate,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.formTemplateTags'),
              flags: [moduleFlag.instantFeedbacks],
            },
          },
          {
            path: '/form-templates/:formTemplateId/factors',
            name: routeName.formTemplateFieldFactors,
            component: CreateFormTemplate,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.fieldFactors'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/form-templates/:formTemplateId/question-bank',
            name: QUESTION_BANK_FORM_TEMPLATES,
            component: CreateFormTemplate,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.questionBank'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/form-templates/:formTemplateId/question-bank/templates',
            name: QUESTION_BANK_TEMPLATE_LIST_FORM_TEMPLATES,
            component: CreateFormTemplate,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.createFormTemplatesList'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/form-templates/:formTemplateId/question-bank/templates/:templateId',
            name: routeName.questionBankTemplateDetailsFormTemplates,
            component: CreateFormTemplate,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.createFormTemplatesList'),
              flags: [moduleFlag.surveys],
            },
          },
        ],
      },
      {
        path: '/new/survey',
        name: NEW_FORM,
        component: NewForm,
        meta: {
          layout: PLAIN,
          title: () => i18n.t('views.newForm'),
          flags: [moduleFlag.surveys],
        },
      },
      {
        path: '/new/survey/preview-templates/:formTemplateId',
        name: NEW_FORM_TEMPLATE_PREVIEW,
        component: NewFormTemplatePreview,
        meta: {
          layout: PLAIN,
          title: () => i18n.t('views.newFormTemplatePreview'),
          flags: [moduleFlag.surveys],
        },
      },
      {
        path: '/check-ins',
        name: UPDATES_EXPLORER,
        component: UpdatesList,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('navigation.updatesExplorer'),
          flags: [moduleFlag.updates],
        },
      },
      {
        path: '/check-in-schedules',
        name: routeName.updateSchedules,
        component: UpdatesListOthers,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('navigation.updateSchedules'),
          flags: [moduleFlag.updates],
        },
      },
      {
        path: '/check-in-schedules/:notificationId',
        name: routeName.updateScheduleDetails,
        component: UpdateScheduleDetails,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('navigation.updateScheduleDetails'),
          flags: [moduleFlag.updates],
        },
      },
      {
        path: '/check-in-templates',
        name: routeName.updateTemplates,
        component: UpdatesListOthers,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('navigation.updateTemplates'),
          flags: [moduleFlag.updates],
        },
      },
      {
        path: '/check-ins/:id',
        name: routeName.updateDetails,
        component: UpdateDetails,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('navigation.updatesExplorer'),
          flags: [moduleFlag.updates],
        },
      },
      {
        path: '/msteams/auth',
        name: routeName.teamsAuth,
        component: msauth,
        meta: {
          layout: PLAIN,
          title: () => '',
          noAuth: true,
        },
      },
      {
        path: '/msteams/team-okrs',
        name: routeName.teamsTeamOkrs,
        component: MSTeamsTeamDetails,
        meta: {
          layout: PLAIN,
          title: () => '',
          flags: [moduleFlag.goals],
        },

      },
      {
        path: '/msteams/account-okrs',
        name: routeName.teamsAccountOkrs,
        component: MSTeamsAccountSpaceRedirect,
        meta: {
          layout: PLAIN,
          title: () => '',
          flags: [moduleFlag.goals],
        },
      },
      {
        path: '/goals',
        name: GOALS_EXPLORER,
        component: GoalsExplorer,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => {
            const goalSettings = Object.values(store.state.nebula.goalSettings);
            if (goalSettings.length === 0 || goalSettings[0].featureNamePlural === '') {
              return i18n.t('goalSettings.featureName.defaultPlural');
            }
            return goalSettings[0].featureNamePlural;
          },
          flags: [moduleFlag.goals],
        },
      },
      { path: '/goals-atlas', redirect: '/goals' },
      {
        path: '/plannings',
        name: routeName.planningList,
        component: Plannings,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('navigation.plannings'),
          flags: [moduleFlag.plannings],
        },
      },
      {
        path: '/plannings/:id',
        name: routeName.planningDetails,
        component: PlanningDetails,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('navigation.planningDetails'),
          flags: [moduleFlag.plannings],
        },
      },
      {
        path: '/public/:savedViewId',
        name: routeName.publishedView,
        component: PublishedViewWrapper,
        meta: {
          layout: PLAIN,
          title: () => 'Goals',
          public: true,
        },
      },
      {
        path: '/goals/:goalId',
        name: GOAL_DETAILS,
        component: GoalDetails,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('views.goalDetails'),
          flags: [moduleFlag.goals],
        },
      },
      {
        path: '/goals-feed',
        name: routeName.goalUpdateFeed,
        component: UpdateFeedExplorer,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('views.goalsUpdateFeed'),
          flags: [moduleFlag.goals],
        },
      },
      {
        path: '/surveys',
        name: FORM,
        component: FormList,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('views.surveyList'),
          flags: [moduleFlag.surveys],
        },
      },
      {
        path: '/survey-wrap/:formId',
        name: FORM_WRAPPER,
        component: FormWrapper,
        meta: {
          layout: PLAIN,
          title: () => i18n.t('views.surveyList'),
          flags: [moduleFlag.surveys],
        },
        children: [
          {
            path: '/surveys/:formId/create',
            name: CREATE_FORM,
            component: CreateForm,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.surveyList'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/surveys/:formId/settings',
            name: FORM_SETTINGS,
            component: CreateForm,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.formSettings'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/surveys/:formId/question-bank',
            name: QUESTION_BANK,
            component: CreateForm,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.questionBank'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/surveys/:formId/question-bank/templates',
            name: QUESTION_BANK_TEMPLATE_LIST,
            component: CreateForm,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.createFormTemplatesList'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/surveys/:formId/question-bank/templates/:templateId',
            name: QUESTION_BANK_TEMPLATE_DETAILS,
            component: CreateForm,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.createFormTemplatesList'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/surveys/:formId/factors',
            name: FIELD_FACTORS,
            component: CreateForm,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.fieldFactors'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/surveys/:formId/share',
            name: FORM_SHARE,
            component: FormShare,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.formShare'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/surveys/:formId/send-settings',
            name: FORM_SEND_SETTINGS,
            component: FormShare,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.formShare'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/surveys/:formId/send-options',
            name: FORM_SEND_OPTIONS,
            component: FormShare,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.formShare'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/surveys/:formId/participants',
            name: FORM_SEND_PARTICIPANTS,
            component: FormShare,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.formShare'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/surveys/:formId/channels',
            name: FORM_SEND_CHANNELS,
            component: FormShare,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.formShare'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/surveys/:formId/verify',
            name: FORM_SEND_VERIFY,
            component: FormShare,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.formShare'),
              flags: [moduleFlag.surveys],
            },
          },
          {
            path: '/surveys/:formId/results',
            name: FORM_RESULTS,
            component: FormResults,
            meta: {
              layout: PLAIN,
              title: () => i18n.t('views.formResults'),
              flags: [moduleFlag.surveys],
            },
          },
        ],
      },
      {
        path: '/surveys/:id',
        name: FORM_DETAILS,
        component: Survey,
        meta: {
          noAuth: true,
          layout: PLAIN,
          title: () => i18n.t('views.survey'),
          flags: [moduleFlag.surveys],
        },
      },
      {
        path: '/surveys/:id/success',
        name: routeName.success,
        component: Success,
        meta: {
          noAuth: true,
          layout: PLAIN,
          title: () => i18n.t('views.surveySuccess'),
          flags: [moduleFlag.surveys],
        },
      },
      {
        path: '/users',
        name: USER_LIST,
        component: UserList,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.userList'),
          accessGroupFlags: routeNameToAccess(USER_LIST),
        },
      },
      {
        path: '/teams',
        name: routeName.spaceList,
        component: SpaceList,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.spaceList'),
          accessGroupFlags: routeNameToAccess(routeName.spaceList),
        },
      },
      {
        path: '/access-groups',
        name: ACCESS_GROUP_LIST,
        component: AccessGroupDetails,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.accessGroupDetails'),
          accessGroupFlags: routeNameToAccess(ACCESS_GROUP_LIST),
        },
      },
      {
        path: '/properties',
        name: USER_FIELDS_NO_SELECTION,
        component: PropertiesView,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.properties'),
          accessGroupFlags: routeNameToAccess(USER_FIELDS_NO_SELECTION),
        },
      },
      {
        path: '/profile-settings',
        name: PROFILE_SETTINGS,
        component: ProfileSettings,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.profileSettings'),
        },
      },
      {
        path: '/my-notifications',
        name: routeName.myNotifications,
        component: MyNotifications,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.myNotifications'),
        },
      },
      {
        path: '/identity-and-provisioning',
        name: routeName.identityAndProvisioning,
        component: IdentityAndProvisioning,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('settingsNavigation.identityAndProvisioning'),
          accessGroupFlags: routeNameToAccess(routeName.identityAndProvisioning),
        },
      },
      {
        path: '/account-settings',
        name: ACCOUNT_SETTINGS,
        component: AccountSettings,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.accountSettings'),
          accessGroupFlags: routeNameToAccess(ACCOUNT_SETTINGS),
        },
      },
      {
        path: '/plans',
        name: routeName.plans,
        component: Plans,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.plans'),
          accessGroupFlags: routeNameToAccess(routeName.plans),
        },
      },
      {
        path: '/integration-settings',
        name: routeName.integrationSettings,
        component: IntegrationSettings,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.integrationSettings'),
          accessGroupFlags: routeNameToAccess(routeName.integrationSettings),
        },
      },
      {
        path: '/data-source/:type',
        name: routeName.dataSourceDetails,
        component: DataSourceDetails,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.manageDataSource'),
        },
      },
      {
        path: '/goal/settings',
        name: routeName.goalSettings,
        component: GoalSettings,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.goalSettings'),
          accessGroupFlags: routeNameToAccess(routeName.goalSettings),
        },
      },
      {
        path: '/goal/cycles',
        name: routeName.goalCycles,
        component: GoalCycles,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.goalCycles'),
          accessGroupFlags: routeNameToAccess(routeName.goalCycles),
        },
      },
      {
        path: '/goal/types/:optionId?',
        name: routeName.goalTypes,
        component: GoalTypes,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.goalTypes'),
          accessGroupFlags: routeNameToAccess(routeName.goalTypes),
        },
      },
      {
        path: '/goal/status',
        name: routeName.goalStatusSettings,
        component: GoalStatusSettings,
        meta: {
          layout: SETTINGS_LAYOUT,
          title: () => i18n.t('views.goalStatus'),
          accessGroupFlags: routeNameToAccess(routeName.goalStatusSettings),
        },
      },
      {
        path: '/onboarding',
        name: routeName.onboarding,
        component: Onboarding,
        meta: {
          layout: PLAIN,
          title: () => i18n.t('views.onboarding'),
        },
      },
      {
        path: '/logout',
        name: routeName.logout,
        component: Logout,
        meta: {
          title: () => '',
          layout: PLAIN,
          noAuth: true,
        },
      },
      {
        path: '/oauth-redirect',
        name: routeName.oauthRedirect,
        component: OAuthRedirect,
        meta: {
          title: () => '',
          layout: PLAIN,
          noAuth: true,
        },
      },
      {
        path: '/oauth2/authorize',
        name: routeName.oAuthCodeGrantFlowAuthorize,
        component: OAuthCodeGrantRedirect,
        meta: {
          title: () => i18n.t('views.authorizationCodeGrantRedirect'),
          layout: PLAIN,
          noAuth: false,
        },
      },
      {
        path: '/404',
        name: routeName.notFound,
        component: NotFound,
        meta: {
          layout: PLAIN,
          title: () => i18n.t('views.notFound'),
          noAuth: true,
        },
      },
      {
        path: '/:pathMatch(.*)*',
        component: NotFound,
        meta: {
          layout: MAIN_LAYOUT,
          title: () => i18n.t('views.notFound'),
        },
      },
    ],
  });

  const enforceOnboarding = (state, getters, to) => {
    if (to.name === routeName.onboarding || to.name === routeName.publishedView) {
      return false;
    }

    if (!state.isLoggedIn) {
      return false;
    }

    return showOnboarding({ user: getters.loggedInUser, account: getters.loggedInUserAccount });
  };

  router.beforeEach((to, from, next) => {
    const { state, getters } = store;
    if (enforceOnboarding(state, getters, to)) {
      next({ name: routeName.onboarding });
      return;
    }

    if (state.isLoggedIn || to.meta.noAuth) {
      document.title = `${to.meta.title()} | Mooncamp`;
    }

    datadogRum.startView(to.name);
    switch (to.name) {
      case GOAL_INSIGHTS:
        EventBus.$emit(EVENTS.INSIGHTS.DASHBOARD_VISITED);
        break;
      case routeName.dashboardDetails:
        EventBus.$emit(EVENTS.INSIGHTS.CUSTOM_DASHBOARD_VISITED);
        break;
      case PROFILE_SETTINGS:
        EventBus.$emit(EVENTS.SETTINGS.PROFILE_SETTINGS_VISITED);
        break;
      default:
        break;
    }

    next();
  });

  return router;
}

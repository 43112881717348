<template>
  <div class="access-policy-link-row">
    <access-policy-scope-row
      class="_link-row"
      :access-type="accessPolicyType.full"
      disabled
      @mouseenter="hover=true"
      @mouseleave="hover = false"
      @click="toggleExpand"
    >
      <template #image>
        <div
          v-if="displayIcon != null && !hover"
          class="_expand-btn"
        >
          <m-icon-display
            :icon="displayIcon.value"
            :size="displayIcon.size"
          />
        </div>
        <m-btn
          v-else
          class="_expand-btn"
          fab
          round
          :icon="expandIcon"
        />
      </template>
      <template #name>
        {{ displayTitle }}
      </template>
      <template #description>
        <template v-if="isPatched">
          {{ $t('accessPolicyLinkRow.permissivePatch') }}
        </template>
        <template v-if="isReplaced">
          {{ $t('accessPolicyLinkRow.restrictivePatch') }}
        </template>
      </template>
      <template #access-policy-type-selector>
        <div>
          <template v-if="!expanded">
            <div class="_type">
              {{ aggregatedAccessTypeText }}
              <m-icon
                class="_icon"
                size="11"
                type="up-down"
              />
            </div>
          </template>
          <template v-else-if="isReplaced">
            <m-btn
              icon="undo"
              :disabled="disabled"
              @click.stop="resetLink"
            >
              {{ $t('accessPolicyLinkRow.resetLink') }}
            </m-btn>
          </template>
        </div>
      </template>
    </access-policy-scope-row>
    <div
      v-show="expanded"
      class="_children"
    >
      <template v-if="linkSource === spaceConfig.model">
        <m-tooltip
          placement="left"
          :disabled="ownersCount === 0"
        >
          <access-policy-scope-row
            icon="team"
            :access-type="accessPolicy.spaceOwnerAccess"
            :access-types="accessTypesWithDisable"
            :disabled="disabled"
            @change="updateOwnerAccess"
          >
            <template #name>
              {{ $t('accessPolicyLinkRow.owners.title') }}
            </template>
            <template
              v-if="ownersCount !== undefined"
              #description
            >
              {{ $t('accessPolicyScopeRow.people', {count: ownersCount}) }}
            </template>
          </access-policy-scope-row>
          <template #title>
            <div class="_overlay">
              <div
                v-for="user in owners"
                :key="user.uid"
                class="_item"
              >
                <user-display
                  :style="{ width: '100%' }"
                  :user="user"
                  xs
                />
              </div>
            </div>
          </template>
        </m-tooltip>
        <m-tooltip
          placement="left"
          :disabled="membersCount === 0"
        >
          <access-policy-scope-row
            icon="team"
            :access-type="accessPolicy.spaceMemberAccess"
            :access-types="accessTypesWithDisable"
            :disabled="disabled"
            @change="updateMemberAccess"
          >
            <template #name>
              {{ $t('accessPolicyLinkRow.members.title') }}
            </template>
            <template
              v-if="membersCount !== undefined"
              #description
            >
              {{ $t('accessPolicyScopeRow.people', {count: membersCount}) }}
            </template>
          </access-policy-scope-row>
          <template #title>
            <div class="_overlay">
              <div
                v-for="userRole in members"
                :key="userRole.user.uid"
                class="_item"
              >
                <user-display
                  :style="{ width: '100%' }"
                  :user="userRole.user"
                  xs
                />
              </div>
            </div>
          </template>
        </m-tooltip>
        <div
          v-for="scope in sortedScopes"
          :key="scope.treeHash"
        >
          <template v-if="canRenderAccessPolicyScope(scope)">
            <access-policy-scope-user-row
              v-if="isStaticUserScope(scope)"
              :user="userFromStaticUserScope(scope)"
              :access-type="scope.accessType"
              :access-types="accessTypesForScope(scope)"
              :disabled="disabled"
              @change="scopeTypeChanged(scope, $event)"
            />
            <access-policy-scope-group-row
              v-else
              :scope="scope"
              :access-type="scope.accessType"
              :access-types="accessTypesForScope(scope)"
              :disabled="disabled"
              @change="scopeTypeChanged(scope, $event)"
            />
          </template>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import AccessPolicyScopeGroupRow from '@/components/access-policy/AccessPolicyScopeGroupRow.vue';
import AccessPolicyScopeRow from '@/components/access-policy/AccessPolicyScopeRow.vue';
import AccessPolicyScopeUserRow from '@/components/access-policy/AccessPolicyScopeUserRow.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useSpace from '@/composables/space/space';
import useSpaces from '@/composables/space/spaces';
import { ACCESS_POLICY_TYPE_MIXED, ACCESS_POLICY_TYPE_REMOVE } from '@/lib/constants';
import { DateTime } from 'luxon';
import { accessPolicyType } from 'shared/constants.json';
import { addOrUpdate } from 'shared/lib/array/write';
import {
  applyPatch,
  composedAccessPolicy,
  isUninitialized,
  resetLink as reset,
  supplementAccessPolicy,
} from '@/lib/access-policy-linking';
import { canRenderAccessPolicyScope, isStaticUserScope, userFromStaticUserScope } from '@/lib/access-policy-scope';
import { computed, ref } from 'vue';
import { copy, shallowCopy } from 'shared/lib/copy';
import { isEmptyIcon } from 'shared/lib/icon';
import { isEqualAccessPolicy } from '@/lib/access-policy';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { space as spaceConfig } from 'shared/api/query/configs.json';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  link: {
    type: Object,
    required: true,
  },
  accessTypes: {
    type: Array,
    default: () => [accessPolicyType.read, accessPolicyType.write, accessPolicyType.full],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const accessTypesWithDisable = [...props.accessTypes, accessPolicyType.disabled];
const accessTypesWithRemove = [...props.accessTypes, ACCESS_POLICY_TYPE_REMOVE];

const emit = defineEmits(['change']);

const hover = ref(false);
const expanded = ref(false);
const expandIcon = computed(() => (expanded.value ? 'down' : 'right'));
const toggleExpand = () => {
  expanded.value = !expanded.value;
};

const linkSource = spaceConfig.model;

const accessPolicy = computed(() => composedAccessPolicy(props.link));
const supplementedLink = computed(() => supplementAccessPolicy(props.link.link));
const isReplaced = computed(() => props.link.deactivatedAt !== null);
const isPatched = computed(() => !isUninitialized(props.link) && !isReplaced.value && !isEqualAccessPolicy(supplementedLink.value, accessPolicy.value));

const spacesSvc = useSpaces();
const space = computed(() => spacesSvc.selectSingle(accessPolicy.value.space.uid));
const spaceSvc = useSpace(space);
const { owners, members, icon: spaceIcon } = spaceSvc;
const ownersCount = computed(() => owners.value.length);
const membersCount = computed(() => members.value.length);

const aggregatedAccessType = computed(() => {
  const types = [
    accessPolicy.value.accountAccess,
    accessPolicy.value.spaceOwnerAccess,
    accessPolicy.value.spaceMemberAccess,
  ];
  types.push(...accessPolicy.value.scopes.map(({ accessType }) => accessType));

  if (types.every((type) => type === types[0])) {
    return types[0];
  }
  return ACCESS_POLICY_TYPE_MIXED;
});
const aggregatedAccessTypeText = computed(() => {
  if (aggregatedAccessType.value === ACCESS_POLICY_TYPE_MIXED) {
    return t('accessPolicyLinkRow.mixedAccess');
  }
  return t(`accessPolicyTypeSelector.${aggregatedAccessType.value}`);
});

const displayTitle = computed(() => {
  switch (linkSource) {
    case spaceConfig.model:
      return t('accessPolicyLinkRow.title', { title: space.value.title });
    default:
      return '';
  }
});
const displayIcon = computed(() => {
  let icon;
  switch (linkSource) {
    case spaceConfig.model:
      icon = spaceIcon.value;
      break;
    default:
      break;
  }
  if (isEmptyIcon(icon)) {
    return null;
  }
  return { value: icon, size: 24 };
});

const sortedScopes = computed(() => shallowCopy(accessPolicy.value.scopes)
  .filter((s) => s?.deletedAt === undefined)
  .sort((a, b) => {
    const aStatic = isStaticUserScope(a);
    const bStatic = isStaticUserScope(b);
    if (aStatic === bStatic) return 0;
    if (aStatic === true) return 1;
    return -1;
  }));

const resetLink = () => {
  emitChange(reset(props.link));
};
const updateOwnerAccess = (newType) => {
  emitChange(applyPatch(props.link, { ...accessPolicy.value, spaceOwnerAccess: newType }));
};
const updateMemberAccess = (newType) => {
  emitChange(applyPatch(props.link, { ...accessPolicy.value, spaceMemberAccess: newType }));
};

const accessTypesForScope = (scope) => {
  if (!isReplaced.value && supplementedLink.value.scopes.find((linkASP) => linkASP.scope.treeHash === scope.scope.treeHash) === undefined) {
    return accessTypesWithRemove;
  }
  return accessTypesWithDisable;
};
const scopeTypeChanged = (scope, newType) => {
  const newScope = copy(scope);
  newScope.accessType = newType;

  if (newType === ACCESS_POLICY_TYPE_REMOVE) {
    newScope.accessType = accessPolicyType.disabled;
    newScope.deletedAt = DateTime.local().toISO();
  }

  const fillHashesToAccessPolicyScope = (aps) => {
    if (!isNullOrUndefined(aps.scope)) {
      return { ...aps, scopeHash: aps.scope.treeHash };
    }
    return aps;
  };
  let scopes = accessPolicy.value.scopes.map(fillHashesToAccessPolicyScope);
  const newSc = [newScope].map(fillHashesToAccessPolicyScope);
  scopes = addOrUpdate(scopes, newSc, 'scopeHash');
  emitChange(applyPatch(props.link, { ...accessPolicy.value, scopes }));
};
const emitChange = (newLink) => {
  if (newLink === null) {
    return;
  }
  emit('change', newLink);
};

</script>

<style scoped lang="scss" type="text/scss">
.access-policy-link-row {
  display: flex;
  flex-direction: column;

  ._link-row {
    ._expand-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3rem;
      width: 3rem;
    }

    ._type {
      padding: 0 1.2rem;
      display: flex;
      gap: 0.8rem;

      ._icon {
        margin-top: .2rem;
        color: $font-color-tertiary;
      }
    }
  }

  ._children {
    padding-left: 1.6rem;
  }
}

._overlay{
  max-width: 24rem;

  ._item{
    min-width: 14rem;
    padding: .2rem 0;
  }
}
</style>

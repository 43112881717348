<template>
  <div class="property-editor">
    <div class="_label">
      {{ textByLang(prop.label, userLang) }}
    </div>
    <div class="_item">
      <goal-cycle-selector
        v-if="prop.edgeName === goalConfig.edges.goalCycle"
        ref="goalCycleSelector"
        v-model:value="value"
        full-width
        show-search
        auto-focus
        keep-open-on-click
        multiple
        wrap
        placement="onTopLeft"
        :placeholder="$t('general.empty')"
      />
      <goal-picker-v2
        v-else-if="prop.edgeName === goalConfig.edges.parents"
        ref="goalPicker"
        v-model:value="value"
        full-width
        :excluded-goal-ids="[...goals.map(g => g.uid)]"
        :disabled-condition="disableCondition"
        :placeholder="$t('general.empty')"
        :multiple="accountSettings.usesMultiGoalAlignment"
        :default-view="defaultView"
        :view-application="viewApplication"
        :ok-text="() => $t('general.select')"
        show-input-field
        wrap
        show-footer
        show-create-view
        show-views-selector
        :initial-selected-cycles="initialCycles"
        placement="onTopCenter"
        :base-filter="goalBaseFilter"
        :max-tag-text-length="50"
        auto-focus
        show-description
      />
      <property-form-item
        v-else
        :property="prop"
        full-width
        auto-focus
        wrap
        disable-links
        :placeholder="$t('general.empty')"
        :property-values="[value]"
        restrict-foreign-entity-selection
        match-trigger-width
        @change="updateValue"
      />
    </div>
    <div class="_actions">
      <m-btn
        class="_btn"
        hide-border
        small
        @click="$emit('close')"
      >
        {{ $t('general.cancel') }}
      </m-btn>
      <m-btn
        color="primary"
        class="_btn"
        small
        :loading="loading"
        @click="save"
      >
        {{ $t('general.save') }}
      </m-btn>
    </div>
  </div>
</template>

<script>
import GoalCycleSelector from '@/components/goal/cycle/GoalCycleSelector.vue';
import GoalPickerV2 from '@/components/goal/GoalPickerV2.vue';
import PropertyFormItem from '@/components/PropertyFormItem.vue';
import intersection from 'lodash-es/intersection';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalProperty from '@/composables/property/goal-property';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useParentSelectorRules from '@/composables/goal/validator/parent-selector-rules';
import useValidator from '@/composables/goal/validator/validator';
import { AND } from 'shared/api/query/constants';
import { emptyValue, initValue } from '@/lib/property';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { logCatch } from '@/lib/logger/logger';
import { stripEntity } from '@/lib/stripper';
import { textByLang } from 'shared/lib/language';
import { validationError, viewApplication, viewType } from 'shared/constants.json';

export default {
  name: 'PropertyEditor',
  props: {
    goals: {
      type: Array,
      required: true,
    },
    prop: {
      type: Object,
      required: true,
    },
    successMessage: {
      type: String,
      default: '',
    },
    goalBaseFilter: {
      type: Object,
      default: () => ({}),
    },
    ignorePrefilled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'edited'],
  setup() {
    const { goalTypeProperty, properties: goalProperties } = useGoalProperty();
    const goalsSvc = useGoals();
    const goalCyclesSvc = useGoalCycle();
    const { userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { accountSettings } = useAccountSettings();

    const { rules } = useParentSelectorRules(goalTypeProperty.value.options);
    const { validate } = useValidator({
      rules,
      usesOKRRules: true,
    });

    return {
      validate,
      goalCyclesSvc,
      goalProperties,
      account: loggedInUserAccount,
      accountSettings,
      loading: goalsSvc.mutateLoading,
      mutateMultiple: goalsSvc.mutateMultiple,
      userLang,
      goalsSvc,
    };
  },
  components: {
    GoalCycleSelector,
    GoalPickerV2,
    PropertyFormItem,
  },
  data() {
    return {
      goalConfig,
      textByLang,
      value: null,
      initialCycles: [],
      viewApplication: viewApplication.goalParentPicker,
    };
  },
  computed: {
    defaultView() {
      return {
        title: this.$t('list.cascade'),
        viewType: viewType.cascade,
        viewApplication: this.viewApplication,
        params: {
          filter: { account: { uid: this.account.uid }, children: [], op: AND },
          props: [],
          order: [],
          wrapCells: true,
        },
      };
    },
    isValid() {
      switch (this.prop.edgeName) {
        case goalConfig.edges.goalCycle:
        case goalConfig.edges.parents:
          return true;
        default:
          return this.value !== null;
      }
    },
  },
  methods: {
    disableCondition(goal) {
      // TODO: In case the goals have different goal types, we cannot validate the goals correctly.
      // A possible solution could be to display a hint to the user.
      return Object.keys(this.validate({ toValidate: goal, selectRulesFrom: this.goals[0] })).length > 0;
    },
    updateValue(value) {
      this.value = value;
    },
    save() {
      if (!this.isValid) {
        return;
      }

      let saveFn = () => this.goalsSvc.updateProperty(this.value, this.prop, this.goals);
      // If used in duplicate to cycle
      if (this.prop.edgeName === goalConfig.edges.goalCycle && this.goals.some((g) => g.uid === undefined)) {
        saveFn = () => this.goalsSvc.mutateMultiple(this.goals.map((g) => ({ ...g, goalCycle: this.value.map(stripEntity) })));
      }

      saveFn().then(() => {
        if (this.successMessage !== '') {
          this.$showSnackbar({ color: 'success', message: this.successMessage });
        }
        this.$emit('close');
        this.$emit('edited');
      }).catch(logCatch((err) => {
        if (err.message.includes(validationError.circularReferenceNotAllowed)) {
          this.$showSnackbar({ color: 'error', message: this.$t('goalMutationErrors.circularReference') });
          return;
        }
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    initGoalCycles() {
      this.initialCycles = this.goalCyclesSvc.selectMultiple(intersection(...this.goals.map((g) => g.goalCycle.map((c) => c.uid))));
    },
    init(ignorePrefilled = false) {
      if (ignorePrefilled) {
        switch (this.prop.edgeName) {
          case goalConfig.edges.goalCycle:
          case goalConfig.edges.parents:
            this.value = [];
            break;
          default:
            this.value = emptyValue(this.prop);
            break;
        }
        return;
      }
      switch (this.prop.edgeName) {
        case goalConfig.edges.goalCycle:
          this.initGoalCycles();
          this.value = [...this.initialCycles];
          break;
        case goalConfig.edges.parents:
          this.initGoalCycles();
          this.value = intersection(...this.goals.map((g) => g.parents.map((c) => c.uid)));
          break;
        default:
          this.value = initValue({ propertyOwners: this.goals, property: this.prop });
          break;
      }
    },
  },
  created() {
    this.init(this.ignorePrefilled);
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .property-editor {
    width: 30rem;

    ._label {
      margin-bottom: .2rem;
      font-size: $font-size-4;
      color: $font-color-secondary;
    }

    ._item {
      margin-bottom: 1rem;
    }

    ._actions {
      display: flex;
      justify-content: flex-end;

      ._btn {
        margin-left: .4rem;
      }
    }
  }
</style>

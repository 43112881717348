<template>
  <component
    :is="clickable ? 'm-tooltip' : 'div'"
    v-if="showWhenNotInPlan || clickable"
  >
    <m-tag
      xxs
      :icon="icon"
      :title="title"
      :clickable="clickable"
      :style="{ textTransform: 'uppercase', fontWeight: '500' }"
      @click="handleClick"
    />
    <template #title>
      {{ canActivate ? $t('planTag.activateHint') : $t('planTag.upgradeHint') }}
    </template>
  </component>
</template>

<script>

import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import { canActivateFeature, mapFeatureToRoute, shouldShowFeatureGate, showFeatureGate } from '@/lib/feature-gate';
import { dataSourceType, featureFlag as featureFlagConfig, productPlan } from 'shared/constants.json';
import { minimumPlanForFeature } from 'shared/lib/plans';

export default {
  name: 'PlanTag',
  props: {
    featureFlag: {
      type: String,
      required: true,
      validator(e) {
        const validFlags = [
          ...featureFlagConfig.all,
          ...dataSourceType.all,
        ];
        return validFlags.includes(e);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { accountSettings } = useAccountSettings();
    const { accountCanActivateFeature } = useAccess();
    return {
      accountCanActivateFeature,
      currentPlan: accountSettings.value.planId,
    };
  },
  computed: {
    requiredPlan() {
      return minimumPlanForFeature(this.featureFlag).id;
    },
    canActivate() {
      return canActivateFeature(this.featureFlag);
    },
    clickable() {
      return !this.disabled
          && shouldShowFeatureGate(this.featureFlag, this.$store)
          && !(this.$route.name === mapFeatureToRoute(this.featureFlag) && this.canActivate);
    },
    showWhenNotInPlan() {
      if (this.currentPlan === productPlan.trial) {
        return true;
      }

      return this.currentPlan !== this.requiredPlan && !this.accountCanActivateFeature([this.featureFlag]);
    },
    title() {
      return this.$t(`plans.short.${this.requiredPlan}`);
    },
    icon() {
      switch (this.requiredPlan) {
        case productPlan.enterprise:
        case productPlan.pro:
        default:
          return 'upgrade-plan';
      }
    },
  },
  methods: {
    handleClick() {
      if (!this.clickable) {
        return;
      }
      showFeatureGate(this.featureFlag, this.$store);
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

</style>

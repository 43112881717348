import {
  accessPolicyScope as accessPolicyScopeConfig,
  userScopeTree as userScopeTreeConfig,
} from 'shared/api/query/configs.json';
import { accessPolicyType, propertyType, userScopeOperator } from 'shared/constants.json';
import { composedAccessPolicy } from '@/lib/access-policy-linking';
import { isNullOrUndefined } from 'shared/lib/object/object';

const orderedRights = [
  undefined,
  '',
  accessPolicyType.disabled,
  accessPolicyType.read,
  accessPolicyType.comment,
  accessPolicyType.write,
  accessPolicyType.full,
];

const orderedRightsIndex = (accessRight) => orderedRights.indexOf(accessRight);

export const cmpAccessRight = (a, b) => orderedRights.indexOf(a) - orderedRights.indexOf(b);

export const getHighestAccessRight = (rights) => {
  const index = getHighestAccessRightIndex(rights);
  return rights[index];
};

export const getHighestAccessRightIndex = (rights) => {
  let index = -1;
  let rightIndex = -1;
  for (let i = 0; i < rights.length; i++) {
    const rightIndexItem = orderedRightsIndex(rights[i]);
    if (rightIndexItem > rightIndex) {
      index = i;
      rightIndex = rightIndexItem;
    }
  }

  return index;
};

const getAccessTypeOfUserForAccessPolicy = (accessPolicy, user, ownerOfSpaces = [], memberOfSpaces = []) => {
  if (isNullOrUndefined(accessPolicy)) {
    return { accessRight: accessPolicyType.disabled, via: 'missingAccessPolicy' };
  }

  if (accessPolicy.accountAccess === accessPolicyType.full) {
    return { accessRight: accessPolicyType.full, via: 'accountAccess', space: accessPolicy.space?.uid };
  }

  const spaceRights = [];
  if (ownerOfSpaces.find((s) => s.uid === accessPolicy.space?.uid) !== undefined) {
    spaceRights.push({ accessRight: accessPolicy.spaceOwnerAccess, via: 'spaceOwnerAccess', space: accessPolicy.space.uid });
  }

  if (memberOfSpaces.find((s) => s.uid === accessPolicy.space?.uid) !== undefined) {
    spaceRights.push({ accessRight: accessPolicy.spaceMemberAccess, via: 'spaceMemberAccess', space: accessPolicy.space.uid });
  }

  const linksRights = accessPolicy.links?.map((link) => getAccessTypeOfUserForAccessPolicy(composedAccessPolicy(link), user, ownerOfSpaces, memberOfSpaces)) ?? [];

  const scopeRights = accessPolicy.scopes?.reduce((res, aps) => {
    if (aps.scopeUsers.find((u) => u.uid === user.uid) !== undefined) {
      return [...res, { accessRight: aps.accessType, via: 'scope', scope: aps.scope?.treeHash, space: accessPolicy.space?.uid }];
    }
    return res;
  }, []) ?? [];
  const rights = [
    { accessRight: accessPolicy.accountAccess, via: 'accountAccess' },
    ...linksRights.map((l) => ({ ...l, link: true })),
    ...scopeRights,
    ...spaceRights,
  ];
  return rights[getHighestAccessRightIndex(rights.map((r) => r.accessRight))];
};

const hasSuperAccess = (accessGroups, superAccessPredicate) => {
  if (superAccessPredicate === '') {
    return { superAccess: false, accessGroup: null };
  }

  const userAccessGroups = accessGroups.filter((ag) => {
    if (ag.accessPolicy.accountAccess === accessPolicyType.read) {
      return true;
    }

    return ag.accessPolicy.scopes.some((s) => s.userIsInScope === true);
  });

  for (let i = 0; i < userAccessGroups.length; i++) {
    const group = userAccessGroups[i];
    if (group[superAccessPredicate] === true) {
      return { superAccess: true, accessGroup: group };
    }
  }
  return { superAccess: false, accessGroup: null };
};

export const getAccessTypeOfUser = (entity, user, accessGroups = [], superAccessPredicate = '') => {
  if (!isNullOrUndefined(entity.creator) && !isNullOrUndefined(user) && entity.creator.uid === user.uid) {
    return { accessRight: accessPolicyType.full, via: 'creator' };
  }

  const { accessPolicy } = entity;
  const spaces = user.values?.find((p) => p.property.type === propertyType.space)?.spaces.filter((s) => s !== undefined) ?? [];
  const ownerOfSpaces = spaces.filter((s) => {
    const p = s.properties.find((p) => p.property.type === propertyType.user);
    return p.users.map((u) => u.uid).includes(user.uid);
  });

  const memberOfSpaces = spaces.filter((s) => {
    const p = s.properties.find((p) => p.property.type === propertyType.user);
    return !p.users.map((u) => u.uid).includes(user.uid);
  });

  const accessRight = getAccessTypeOfUserForAccessPolicy(accessPolicy, user, ownerOfSpaces, memberOfSpaces);
  if (accessRight.accessRight === accessPolicyType.disabled) {
    return accessRight;
  }

  const { superAccess, accessGroup } = hasSuperAccess(accessGroups, superAccessPredicate);
  if (superAccess) {
    return { accessRight: accessPolicyType.full, via: 'superAccess', accessGroup };
  }

  return accessRight;
};

const copyUserScope = (us) => {
  const res = { type: us.type };
  if (us.property !== undefined) {
    res.property = us.property;
  }
  if (us.selectedOptions !== undefined) {
    res.selectedOptions = us.selectedOptions;
  }
  if (us.spaces !== undefined) {
    res.spaces = us.spaces;
  }
  if (us.staticUsers !== undefined) {
    res.staticUsers = us.staticUsers;
  }
  return res;
};

export const copyUserScopeTree = (ust) => ({
  treeHash: ust.treeHash,
  account: ust.account,
  op: ust.op,
  children: [{
    op: ust.children[0].op,
    children: [{
      op: ust.children[0].children[0].op,
      scope: copyUserScope(ust.children[0].children[0].scope),
    }],
  }],
});

export const copyAccessPolicy = (policy) => {
  if (isNullOrUndefined(policy)) {
    return null;
  }
  const res = { ...policy };
  delete res.uid;

  if (Array.isArray(res.links)) {
    res.links = res.links.map((l) => {
      const cp = { ...l };
      delete cp.uid;
      cp.patch = copyAccessPolicy(cp.patch);
      return cp;
    });
  }

  if (Array.isArray(res.scopes)) {
    res.scopes = res.scopes.map((s) => {
      const cp = { ...s };
      delete cp.uid;
      cp.scope = copyUserScopeTree(cp.scope);
      return cp;
    });
  }

  return res;
};

/* returns scopes, that are common in a list of access policy scopes (array of array of scopes) */
export const accessPolicyCommonScopes = (accessPolicyScopes) => {
  if (accessPolicyScopes.length === 0) {
    return [];
  }

  return accessPolicyScopes.reduce(
    (res, next) => res.filter((r) => next.find((s) => {
      if (r.accessType !== s.accessType) {
        return false;
      }

      if (s.scope !== null && r.scope !== null) {
        return s.scope.treeHash === r.scope.treeHash;
      }

      return false;
    })),
    accessPolicyScopes[0],
  );
};

export const spreadAccessPolicyScopes = (accessPolicyScope) => accessPolicyScope.scope.children.map((c) => c.children[0].scope)
  .reduce((acc, us) => {
    const splitFlatObject = (obj) => {
      const arrayField = Object.keys(obj).find((k) => Array.isArray(obj[k]) && obj[k].length > 0);
      if (arrayField === undefined) {
        return obj;
      }

      return obj[arrayField].map((el) => ({
        ...obj,
        [arrayField]: [el],
      }));
    };
    splitFlatObject(us).forEach((us) => {
      acc.push({
        [userScopeTreeConfig.edges.account]: accessPolicyScope.scope.account,
        [userScopeTreeConfig.edges.op]: userScopeOperator.or,
        [userScopeTreeConfig.edges.children]: [{
          [userScopeTreeConfig.edges.op]: userScopeOperator.and,
          [userScopeTreeConfig.edges.children]: [{
            [userScopeTreeConfig.edges.op]: userScopeOperator.and,
            [userScopeTreeConfig.edges.scope]: us,
          }],
        }],
      });
    });
    return acc;
  }, [])
  .map((ust) => ({
    [accessPolicyScopeConfig.edges.accessType]: accessPolicyScope[accessPolicyScopeConfig.edges.accessType],
    [accessPolicyScopeConfig.edges.scope]: ust,
  }));

const isEqualModel = (a, b) => {
  if (a === null && b === null) {
    return true;
  }
  if (a !== null && b !== null) {
    return a.uid === b.uid;
  }
  return false;
};

export const isEqualAccessPolicy = (a, b) => {
  if (!isEqualModel(a.account, b.account)) {
    return false;
  }
  if (!isEqualModel(a.space, b.space)) {
    return false;
  }

  if (a.accountAccess !== b.accountAccess) { return false; }
  if (a.spaceOwnerAccess !== b.spaceOwnerAccess) { return false; }
  if (a.spaceMemberAccess !== b.spaceMemberAccess) { return false; }

  const fromA = a.scopes.map((aAPS) => {
    const bAPS = b.scopes.find((bAPS) => aAPS.scope.treeHash === bAPS.scope.treeHash);
    if (bAPS === undefined) {
      return false;
    }

    return aAPS.accessType === bAPS.accessType;
  });

  const fromB = b.scopes.filter((bAPS) => {
    const aAPS = a.scopes.find((aAPS) => bAPS.scope.treeHash === aAPS.scope.treeHash);
    return aAPS === undefined;
  });
  return [...fromA, fromB.length === 0].every((el) => el);
};

import config from '@/nebula/delete-graph-config';
import { addToDenormalize, denormalize, dn, findReverseReferences, mergeToDenormalize } from '@/nebula/denormalize';
import { createReferenceIndex, references, resetReferences } from '@/nebula/references';
import { deleteEntities, findToDelete } from '@/nebula/delete-graph';
import { isEqual } from 'lodash-es';
import { normalize } from 'normalizr';
import { resetState } from '@/nebula/initial-state';
import { syncReverseEdges } from '@/nebula/reverse-edges';

export default function mutations(postDenormalizeSvc, facetsSvc) {
  return {
    MUTATE(state, { entities, model, schema }) {
      const toCreate = {};
      const toDenormalize = {};
      const nd = normalize(entities, [schema[model]]);
      const models = Object.keys(nd.entities);
      models.forEach((m) => {
        createReferenceIndex(references, schema[m], nd.entities[m]);
        Object.values(nd.entities[m]).forEach((e) => {
          if (schema[m].simple) {
            state[m][e.uid] = e;
            return;
          }

          if (Object.keys(e).length === 1 || e.uid === undefined) {
            return;
          }

          const stateExists = state[m] !== undefined && state[m][e.uid] !== undefined;
          if (stateExists) {
            const commonKeys = Object.keys(e).filter((key) => state[m][e.uid][key] !== undefined);
            if (commonKeys.length === Object.keys(e).length) {
            // isEqual assumes same order within arrays in order to show equality.
            // This check is mainly here fore performance improvements in VueX's strict mode
            // to minimize writes to the store.
              const notEqual = commonKeys.some((key) => !isEqual(state[m][e.uid][key], e[key]));
              if (!notEqual) {
              // if state exists and is same
                return;
              }
            }
          }

          const localToDenormalize = {};
          addToDenormalize(e.uid, m, localToDenormalize);
          findReverseReferences(localToDenormalize, references, schema);
          syncReverseEdges(state, references, schema, localToDenormalize, e, m);
          mergeToDenormalize(toDenormalize, localToDenormalize);

          if (state[m] === undefined || state[m][e.uid] === undefined) {
            if (toCreate[m] === undefined) {
              toCreate[m] = {};
            }
            toCreate[m][e.uid] = e;
            return;
          }

          if (schema[m].mergeStrategy !== undefined) {
            state[m][e.uid] = schema[m].mergeStrategy(state[m][e.uid], e);
            return;
          }

          state[m][e.uid] = {
            ...state[m][e.uid],
            ...e,
          };
        });
      });
      Object.keys(toCreate).forEach((m) => {
        state[m] = {
          ...state[m],
          ...toCreate[m],
        };
      });

      facetsSvc.extractFacets(entities, model);

      findReverseReferences(toDenormalize, references, schema);
      denormalize(toDenormalize, state, schema, facetsSvc.applyFacets, postDenormalizeSvc.postDenormalize);
    },
    MUTATE_SINGLE(state, { entity, model, schema }) {
      this.commit('nebula/MUTATE', { entities: [entity], model, schema });
    },
    QUERY(state, { queries, response, schema }) {
      queries.forEach((q) => {
        if (response[q.alias] === undefined) {
          return;
        }
        this.commit('nebula/MUTATE', { entities: response[q.alias], model: q.model, schema });
      });
    },
    DELETE(state, { ids, model, schema }) {
      const toDenormalize = {};
      const toDelete = [...ids.map((uid) => ({ uid, type: model })), ...findToDelete(references, ids, model, schema, config, state)];

      const deletedAt = new Date();
      toDelete.forEach((delCmd) => {
        addToDenormalize(delCmd.uid, delCmd.type, toDenormalize);
        syncReverseEdges(state, references, schema, toDenormalize, { uid: delCmd.uid, deletedAt }, delCmd.type);
      });
      findReverseReferences(toDenormalize, references, schema);

      deleteEntities(toDelete, state, dn, references);
      denormalize(toDenormalize, state, schema, facetsSvc.applyFacets, postDenormalizeSvc.postDenormalize);
    },
    DELETE_SINGLE(state, { id, model, schema }) {
      this.commit('nebula/DELETE', { ids: [id], model, schema });
    },
    RESET(state, { schema }) {
      resetState(state, schema);
      resetReferences();
    },
  };
}

<template>
  <m-content
    padding-xs
    class="access-policy-scope-row"
  >
    <div class="_display">
      <slot name="display">
        <div
          v-if="image !== '' || icon !== '' || !!$slots.image"
          class="_left"
        >
          <slot name="image">
            <m-avatar
              :size="30"
              :src="image"
              :icon="icon"
            />
          </slot>
        </div>
        <div class="_middle">
          <div class="_title">
            <slot name="name" />
          </div>
          <div class="_subtitle">
            <slot name="description" />
          </div>
        </div>
      </slot>
    </div>
    <div class="_access-type">
      <slot name="access-policy-type-selector">
        <access-policy-type-selector
          :value="accessType"
          :access-types="accessTypes"
          :disabled="disabled"
          @change="emit('change', $event)"
        />
      </slot>
    </div>
  </m-content>
</template>
<script setup>

import AccessPolicyTypeSelector from '@/components/access-policy/AccessPolicyTypeSelector.vue';
import { accessPolicyType } from 'shared/constants.json';

defineProps({
  image: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
  accessType: {
    type: String,
    default: '',
  },
  accessTypes: {
    type: Array,
    default: () => [accessPolicyType.read, accessPolicyType.write, accessPolicyType.full],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['change']);

</script>

<style scoped lang="scss" type="text/scss">
.access-policy-scope-row {
  display: flex;
  min-height: 5.4rem;
  gap: .8rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ._display {
    display: flex;
    align-items: center;
    max-width: 28.5rem;

    ._left {
      flex: 0 0 3rem;
      margin-right: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ._middle {
      flex: 1 1 0;
      white-space: nowrap;
      overflow: hidden;

      ._title {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      ._subtitle {
        font-size: $font-size-2;
        color: $font-color-secondary;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>

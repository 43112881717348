import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { accessGroup as accessGroupConfig, goal as goalConfig, gridPage as gridPageConfig } from 'shared/api/query/configs.json';
import { camelCase } from 'lodash';
import { getAccessTypeOfUser } from '@/lib/access-policy';

export default function usePostDenormalizeMiddlewares(postDenormalizeSvc) {
  const { loggedInUser } = useLoggedInUser();

  const accessTypeMiddleware = (model, superAccess) => (toDenormalize, dn, state, schema) => {
    Object.keys(toDenormalize).forEach((uid) => {
      if (dn.value[model][uid] === undefined) {
        return;
      }
      const { accessRight } = getAccessTypeOfUser(dn.value[model][uid], loggedInUser.value, Object.values(dn.value.accessGroup), superAccess);
      dn.value[model][uid].accessRight = accessRight;
    });
  };

  const accessGroupMiddleware = (toDenormalize, dn, state, schema) => {
    const models = [goalConfig.model, gridPageConfig.model];
    models.forEach((model) => {
      accessTypeMiddleware(model, `${camelCase(model)}SuperAccess`)(dn.value[model], dn, state, schema);
    });
  };

  const middlewares = [
    { model: goalConfig.model, fn: accessTypeMiddleware(goalConfig.model, 'goalSuperAccess') },
    { model: gridPageConfig.model, fn: accessTypeMiddleware(gridPageConfig.model, 'gridPageSuperAccess') },
    { model: accessGroupConfig.model, fn: accessGroupMiddleware },
  ];

  postDenormalizeSvc.setMiddlewares(middlewares);
}

export default function usePostDenormalize() {
  const postDenormalizeMiddlewares = {};

  const setMiddlewares = (middlewares) => {
    middlewares.forEach((middleware) => {
      if (postDenormalizeMiddlewares[middleware.model] === undefined) {
        postDenormalizeMiddlewares[middleware.model] = [];
      }
      postDenormalizeMiddlewares[middleware.model].push(middleware.fn);
    });
  };

  const postDenormalize = (toDenormalize, dn, state, schema) => {
    Object.keys(toDenormalize).forEach((model) => {
      if (postDenormalizeMiddlewares[model] === undefined) {
        return;
      }
      postDenormalizeMiddlewares[model].forEach((middleware) => {
        middleware(toDenormalize[model], dn, state, schema);
      });
    });
  };

  return { postDenormalize, setMiddlewares };
}

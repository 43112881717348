import { DEFAULT_ATTRIBUTES, UID } from 'shared/api/query/constants';
import { accessPolicyChildren } from '@/api/query/nebula/access-policy';
import {
  accessPolicy as accessPolicyConfig,
  gridPage as gridPageConfig,
  gridPageRow as gridPageRowConfig,
  storageObject as storageObjectConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';
import { storageObjectEdges } from '@/api/query/nebula/storage-object';

export const gridPageChildren = [
  ...DEFAULT_ATTRIBUTES,
  {
    attr: gridPageConfig.edges.creator,
    model: userConfig.model,
    children: [{ attr: UID }],
    default: null,
  },
  { attr: gridPageConfig.edges.title, default: '' },
  { attr: gridPageConfig.edges.icon, default: '' },
  {
    attr: gridPageConfig.edges.image,
    model: storageObjectConfig.model,
    children: storageObjectEdges,
    default: null,
  },
  { attr: gridPageConfig.edges.description, default: null },
  { attr: gridPageConfig.edges.rowOrder, default: [] },
  {
    attr: reverseEdge(gridPageRowConfig.edges.gridPage),
    alias: 'rows',
    model: gridPageRowConfig.model,
    default: [],
    children: [{ attr: UID }],
  },
  {
    attr: gridPageConfig.edges.accessPolicy,
    model: accessPolicyConfig.model,
    children: accessPolicyChildren,
  },
  { attr: gridPageConfig.edges.isPublic },
];

<template>
  <component
    :is="componentType"
    ref="contextMenu"
    class="goals-context-menu"
    :relocate-key="relocateKey"
    @hide="reset"
  >
    <m-card
      v-if="showMenu === 'menu'"
      list
      no-padding
    >
      <template v-if="!readOnly">
        <m-card-item
          icon="delete"
          :disabled="editableGoals.length === 0"
          :tooltip="editableGoalsTooltip"
          :loading="deleteLoading"
          @click="handleDelete"
        >
          {{ $t('general.delete') }}
          <template
            v-if="cannotEditAllGoals && editableGoals.length > 0"
            #right
          >
            <m-tooltip>
              <m-icon type="warning" />
              <template #title>
                {{
                  $t('goalsContextMenu.deleteHint', { title: goalSettings.featureNamePlural })
                }}
              </template>
            </m-tooltip>
          </template>
        </m-card-item>
        <goal-create-dropdown
          v-if="canCreate && canCreateSubgoal && !isNullOrUndefined(selectedGoal)"
          :goal="selectedGoal"
          block
          placement="topRight"
          trigger="hover"
          @create-beneath="handleCreateBeneath"
        >
          <template #default="goalCreateDropdownProps">
            <m-tooltip :disabled="!goalCreateDropdownProps.disabled">
              <m-card-item
                icon="apartment"
                :loading="createSubgoalLoading"
                :disabled="goalCreateDropdownProps.disabled"
              >
                {{ $t('goalsContextMenu.addBeneath') }}
                <template #right>
                  <m-icon
                    v-if="!goalCreateDropdownProps.disabled"
                    type="chevron-right"
                    :color="$colors.grey.lighten1"
                  />
                </template>
              </m-card-item>
              <template #title>
                {{ $t('goalsContextMenu.noTypeAllowed') }}
              </template>
            </m-tooltip>
          </template>
        </goal-create-dropdown>
        <m-card-item
          v-if="canCreate"
          icon="copy"
          :loading="duplicateLoading"
          @click="handleDuplicate"
        >
          {{ $t('general.duplicate') }}
        </m-card-item>
        <m-card-item
          v-if="canCreate && duplicatableToCycleGoals.length > 0"
          icon="retweet"
          @click="duplicateToCycle"
        >
          {{ $t('goalsContextMenu.duplicateToCycle') }}
        </m-card-item>
        <edit-goal-property-item
          :disabled="editableGoals.length === 0"
          :tooltip="editableGoalsTooltip"
          trigger="hover"
          @edit-property="editProperty"
        />
        <m-card-item
          v-if="fullAccessGoals.length === 0"
          icon="lock"
          :tooltip="editableGoalsTooltip"
          @click="changeAccess"
        >
          {{ $t('goalsContextMenu.changeAccess') }}
          <template
            v-if="cannotChangeAccessAllGoals && fullAccessGoals.length > 0"
            #right
          >
            <m-tooltip>
              <m-icon type="warning" />
              <template #title>
                {{ $t('goalsContextMenu.changeAccessHint', { title: goalSettings.featureNamePlural }) }}
              </template>
            </m-tooltip>
          </template>
        </m-card-item>
        <m-card-item
          v-if="!isNullOrUndefined(selectedGoal) && selectedGoal.progressMeasurement === goalProgressMeasurement.alignedItems"
          icon="sync"
          :style="{ minWidth: '30rem' }"
          :tooltip="$t('goalsContextMenu.statusUpdateTooltip')"
          :loading="automaticStatusUpdateLoading"
          tooltip-placement="left"
          @click="handleToggleStatusUpdate"
        >
          <m-switch
            :value="!selectedGoal.disableStatusAutoUpdate"
            small
            :disabled="cannotEditAllGoals"
            :label="$t('goalsContextMenu.disableStatusAutoUpdate')"
          />
        </m-card-item>
        <template v-if="addableToDashboardPublishedItems.length > 0">
          <m-divider xxs />
          <m-card-item
            emoji="ReconciliationOutlined"
            :tooltip="addableToDashboardItemsTooltip"
            :disabled="addableToDashboardItems.length === 0"
            @click="addToDashboard"
          >
            {{ $t('goalsContextMenu.addToDashboard') }}
            <template
              v-if="cannotAddToDashboardAllGoals && addableToDashboardItems.length > 0"
              #right
            >
              <m-tooltip>
                <m-icon type="warning" />
                <template #title>
                  {{ $t('goalsContextMenu.addToDashboardHint', { title: goalSettings.featureNamePlural }) }}
                </template>
              </m-tooltip>
            </template>
          </m-card-item>
        </template>
      </template>
      <template v-if="showExpandButtons">
        <m-divider xxs />
        <m-card-item
          icon="expand-plus"
          @click="handleExpandAll"
        >
          {{ $t('documentListHeader.expandAll') }}
        </m-card-item>
        <m-card-item
          icon="expand-minus"
          @click="handleCollapseAll"
        >
          {{ $t('documentListHeader.collapseAll') }}
        </m-card-item>
      </template>
    </m-card>
    <m-card
      v-else-if="showMenu === 'prop-editor'"
      padding-xs
    >
      <property-editor
        :prop="prop"
        :goals="toEdit"
        :ignore-prefilled="ignorePrefilled"
        :success-message="successMessage"
        :goal-base-filter="goalBaseFilter"
        @close="hideMenu"
        @edited="$emit('prop-edited', prop)"
      />
    </m-card>
    <m-card
      v-else-if="showMenu === 'access-editor'"
      no-padding
    >
      <access-editor
        :items="toEdit"
        :default-access-policy="newGoalDefaultAccessPolicy"
        :update-entities-fn="updateGoalsAccessPolicy"
        :success-message="successMessage"
        :access-types="[accessPolicyType.read, accessPolicyType.comment, accessPolicyType.write, accessPolicyType.full]"
        @input="relocateKey += 1"
        @close="hideMenu"
        @edited="$emit('access-edited')"
      />
    </m-card>
    <m-card
      v-else-if="showMenu === 'add-to-grid-editor'"
      padding-xs
    >
      <add-to-grid-editor
        :items="toEdit"
        @close="hideMenu"
      />
    </m-card>
  </component>
</template>

<script>
import AccessEditor from '@/components/access-policy/AccessEditor.vue';
import AddToGridEditor from '@/components/custom-grid/AddToGridEditor.vue';
import EditGoalPropertyItem from '@/components/goal/EditGoalPropertyItem.vue';
import GoalCreateDropdown from '@/components/goal/GoalCreateDropdown.vue';
import PropertyEditor from '@/components/goal/PropertyEditor.vue';
import useDeleteGoal from '@/composables/goal/delete-goal';
import useDuplicateGoal from '@/composables/goal/duplicate-goal';
import useGoalAccessPolicy from '@/composables/goal/access-policy';
import useGoalPickerFilter from '@/composables/goal/goal-picker-filter';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useStatusAutoUpdate from '@/composables/goal/status-auto-update';
import { accessPolicyType, goalProgressMeasurement, propertyType } from 'shared/constants.json';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'GoalsContextMenu',
  props: {
    componentType: {
      type: String,
      default: 'm-context-menu',
    },
    goalIds: {
      type: Array,
      required: true,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    canCreateSubgoal: {
      type: Boolean,
      default: false,
    },
    createSubgoalLoading: {
      type: Boolean,
      default: false,
    },
    showExpandButtons: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['prop-edited', 'access-edited', 'expand-all', 'collapse-all', 'goals-deleted', 'goals-duplicated', 'create-beneath', 'hide', 'relocate'],
  setup() {
    const { userLang } = useLoggedInUser();
    const { goalPickerFilter } = useGoalPickerFilter();
    const { deleteLoading, showDeleteGoalModal } = useDeleteGoal();
    const { updateAccessPolicy } = useGoalAccessPolicy();
    const { duplicate, prepareDuplicate, duplicateLoading } = useDuplicateGoal();
    const { goalSettings, newGoalDefaultAccessPolicy } = useGoalSettings();
    const { selectSingle, selectMultiple } = useGoals();
    const { automaticStatusUpdateLoading, toggleStatusUpdate } = useStatusAutoUpdate();
    return {
      userLang,
      goalBaseFilter: goalPickerFilter,
      updateGoalsAccessPolicy: updateAccessPolicy,
      duplicate,
      prepareDuplicate,
      duplicateLoading,
      goalSettings,
      selectSingle,
      selectMultiple,
      newGoalDefaultAccessPolicy,
      deleteLoading,
      showDeleteGoalModal,
      automaticStatusUpdateLoading,
      toggleStatusUpdate,
    };
  },
  components: {
    GoalCreateDropdown,
    AddToGridEditor,
    EditGoalPropertyItem,
    PropertyEditor,
    AccessEditor,
  },
  data() {
    return {
      showMenu: 'menu',
      prop: null,
      toEdit: [],
      ignorePrefilled: false,
      successMessage: '',
      accessPolicyType,
      goalProgressMeasurement,
      relocateKey: 0,
    };
  },
  computed: {
    selectedGoal() {
      if (this.goalIds.length !== 1) {
        return null;
      }
      return this.selectSingle(this.goalIds[0]);
    },
    selectedGoals() {
      return this.selectMultiple(this.goalIds);
    },
    editableGoals() {
      return this.selectedGoals.filter((g) => [accessPolicyType.write, accessPolicyType.full].includes(g.accessRight));
    },
    fullAccessGoals() {
      return this.selectedGoals.filter((g) => g.accessRight === accessPolicyType.full);
    },
    cannotEditAllGoals() {
      return this.editableGoals.length !== this.selectedGoals.length;
    },
    cannotChangeAccessAllGoals() {
      return this.fullAccessGoals.length !== this.selectedGoals.length;
    },
    editableGoalsTooltip() {
      if (this.editableGoals.length > 0) {
        return '';
      }
      return this.$t('goalsContextMenu.cannotDeleteMultiple', this.editableGoals.length, { title: this.editableGoals.length === 1 ? this.goalSettings.featureNameSingular : this.goalSettings.featureNamePlural });
    },
    duplicatableToCycleGoals() {
      return this.selectedGoals.filter((g) => g.publishedAt !== null);
    },
    addableToDashboardPublishedItems() {
      return this.selectedGoals.filter((g) => g.publishedAt !== null && g.publishedAt !== undefined);
    },
    addableToDashboardItems() {
      return this.selectedGoals.filter((g) => {
        const validProgressMeasurement = [goalProgressMeasurement.alignedItems, goalProgressMeasurement.threshold, goalProgressMeasurement.continuous].includes(g.progressMeasurement);
        const isPublished = g.publishedAt !== null && g.publishedAt !== undefined;
        return validProgressMeasurement && isPublished;
      });
    },
    cannotAddToDashboardAllGoals() {
      return this.addableToDashboardItems.length !== this.selectedGoals.length;
    },
    addableToDashboardItemsTooltip() {
      if (this.addableToDashboardItems.length > 0) {
        return '';
      }

      return this.$t('goalsContextMenu.cannotAddToDashboard');
    },
  },
  methods: {
    isNullOrUndefined,
    handleDelete() {
      this.showDeleteGoalModal({ goals: this.editableGoals }).then(() => {
        this.$emit('goals-deleted');
        this.hideMenu();
      });
    },
    handleCreateBeneath(payload) {
      this.$emit('create-beneath', payload);
      this.hideMenu();
    },
    handleDuplicate() {
      this.duplicate(this.duplicatableToCycleGoals).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.duplicated') });
        this.$emit('goals-duplicated');
        this.hideMenu();
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    handleToggleStatusUpdate() {
      this.toggleStatusUpdate(this.selectedGoal).then(this.hideMenu);
    },
    handleExpandAll() {
      this.$emit('expand-all');
      this.hideMenu();
    },
    handleCollapseAll() {
      this.$emit('collapse-all');
      this.hideMenu();
    },
    show(event) {
      this.$refs.contextMenu.show(event);
    },
    hideMenu() {
      if (this.$refs.contextMenu !== null && typeof this.$refs.contextMenu !== 'undefined' && typeof this.$refs.contextMenu.hide === 'function') {
        this.$refs.contextMenu.hide();
        return;
      }

      this.$emit('hide');
    },
    reset() {
      this.showMenu = 'menu';
      this.relocateKey = 0;
      this.ignorePrefilled = false;
    },
    editProperty({ prop }) {
      this.toEdit = this.editableGoals;
      this.prop = prop;
      this.ignorePrefilled = false;
      this.showMenu = 'prop-editor';
      this.successMessage = this.$t('success.updated');
    },
    changeAccess() {
      this.toEdit = this.fullAccessGoals;
      this.showMenu = 'access-editor';
      this.successMessage = this.$t('success.updated');
    },
    duplicateToCycle() {
      this.toEdit = this.prepareDuplicate(this.editableGoals);
      this.prop = {
        label: { [this.userLang]: this.$t('goalsFilter.cycle') },
        edgeName: goalConfig.edges.goalCycle,
        type: propertyType.options,
      };
      this.ignorePrefilled = true;
      this.showMenu = 'prop-editor';
      this.successMessage = this.$t('success.duplicated');
    },
    addToDashboard() {
      this.toEdit = this.addableToDashboardItems;
      this.showMenu = 'add-to-grid-editor';
    },
  },
  watch: {
    createSubgoalLoading(newVal, oldVal) {
      if (!this.showMenu) {
        return;
      }

      if (newVal === false && oldVal === true) {
        this.hideMenu();
      }
    },
    showMenu() {
      this.relocateKey += 1;
    },
    relocateKey(val) {
      this.$emit('relocate', val);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>

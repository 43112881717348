import * as actions from '@/store/actions';
import * as getters from '@/store/getters';
import mutations from '@/store/mutations';
import { createStore } from 'vuex';
import { getDefaultState } from '@/store/helper';

const debug = import.meta.env.DEV;

const state = getDefaultState();

export default function useStoreInit(modules) {
  const config = {
    state,
    actions,
    mutations,
    getters,
    modules,
  };

  /* eslint-disable new-cap */
  return new createStore({
    ...config,
    strict: debug,
  });
  /* eslint-enable new-cap */
}
